@import "_globals";

input.ui-autocomplete-input--open {
    outline: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-color: @c-gray-450 !important;
    border-bottom-color: @c-white !important;
}

.ui-autocomplete {
    .mx-shadow-lg();
    background-color: @c-white;
    border: @c-gray-450 1px solid;
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding: 10px 0;
    position: absolute;
    list-style: none;
    z-index: 101;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 26px;
        right: 26px;
        height: 1px;
        background-color: @c-gray-400;
    }

    > .ui-menu-item {
        padding: 3px 26px;
        cursor: pointer;

        > a {
            color: @c-gray-300;
            cursor: pointer;
            transition-duration: 0s;

            strong {
                font-weight: 400;
            }
        }

        &.ui-state-focus {
            background-color: @c-blue-200;

            > a {
                color: #fff;
            }
        }
    }

    &.big > .ui-menu-item {
        font-size: 23px;
        padding: 17px 10px;
    }
}

.ui-helper-hidden-accessible {
    display: none;
}