input.ui-autocomplete-input--open {
  outline: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #DBDADA !important;
  border-bottom-color: #ffffff !important;
}
.ui-autocomplete {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: #DBDADA 1px solid;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 10px 0;
  position: absolute;
  list-style: none;
  z-index: 101;
}
.ui-autocomplete:before {
  content: '';
  position: absolute;
  top: 0;
  left: 26px;
  right: 26px;
  height: 1px;
  background-color: #b2b2b2;
}
.ui-autocomplete > .ui-menu-item {
  padding: 3px 26px;
  cursor: pointer;
}
.ui-autocomplete > .ui-menu-item > a {
  color: #7a7a7a;
  cursor: pointer;
  transition-duration: 0s;
}
.ui-autocomplete > .ui-menu-item > a strong {
  font-weight: 400;
}
.ui-autocomplete > .ui-menu-item.ui-state-focus {
  background-color: #0080bc;
}
.ui-autocomplete > .ui-menu-item.ui-state-focus > a {
  color: #fff;
}
.ui-autocomplete.big > .ui-menu-item {
  font-size: 23px;
  padding: 17px 10px;
}
.ui-helper-hidden-accessible {
  display: none;
}
